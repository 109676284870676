<template>
  <div class="d-flex">
    <div
      v-for="(amenity, amenityIndex) in amenities"
      :key="`supported-amenity-${amenity.id}-${amenityIndex}`"
      style="margin-right: 2px"
    >
      <CRIcon class="amenity-icon" width="16px" height="16px" view-box="0 0 24 24">
        {{ amenity.key | convertAmenityKeyToCRIcon }}
      </CRIcon>
    </div>
  </div>
</template>

<script>
import { convertAmenityKeyToCRIcon } from '@/utils/string'
import { AmenityTypeId } from '@/utils/enum'

export default {
  filters: {
    convertAmenityKeyToCRIcon,
  },
  props: {
    bid: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    amenities() {
      const amenityMap = {}

      const vehicleTypeAmenities = this.bid.vehicleTypeAmenities
      if (vehicleTypeAmenities) {
        // Flatten and de-duplicate vehicleTypeAmenities
        for (const vehicleType of Object.values(vehicleTypeAmenities)) {
          for (const amenity of vehicleType) {
            amenityMap[amenity.id] = amenity
          }
        }
      }

      // Search for SPAB and ADA amenities in companyAmenities and update amenityMap
      const specialAmenityTypes = ['SPAB', 'ADA']
      for (const typeId of specialAmenityTypes) {
        const foundAmenity = this.bid.compliances.find(
          ({ id }) => id === AmenityTypeId[typeId]
        )
        if (!foundAmenity) {
          continue
        }
        amenityMap[AmenityTypeId[typeId]] = foundAmenity
      }

      return Object.values(amenityMap)
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.amenity-icon {
  color: $gray;
}
</style>
