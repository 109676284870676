<template>
  <v-layout align-stretch justify-center style="margin-bottom: 45px" no-gutters>
    <v-flex xs12 lg5 md7 sm8 style="text-align: center">
      <v-img
        :src="require('@/assets/images/404.svg')"
        position="center"
        style="margin-top: 24px; margin-bottom: 24px"
      />
      <div class="h0">
        <slot />
      </div>
      <v-flex>Oops! We took a wrong turn.</v-flex>
      <v-flex>
        {{ message }}
        <br />
        Please check your url or return to the
        <router-link :to="{ name: 'home' }">home page</router-link>
        .
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'This page does not exist or some other error has occurred.',
    },
  },
}
</script>

<style scoped>
.h0 {
  font-family: 'Airbnb Cereal App Medium';
  font-size: 40px;
}
</style>
