import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Bid, BidAudit } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getBids(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Bid>>> {
    // FIXME: not sure why this needs to be done to maintain the right api URL.
    baseUrl()

    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`/tables/bids?${query}`)
  },
  getBidAudits(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<BidAudit>>> {
    baseUrl()

    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      bidId = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`/bids/${bidId}/audits?${query}`)
  },
  getAllBidAudits(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<BidAudit>>> {
    baseUrl()

    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`tables/bidAudits?${query}`)
  },
  exportMarketBids(params: { sorts: any, filters: any }): Promise<AxiosResponse> {
    baseUrl()
    const { sorts = null, filters = null } = params

    let query = `&isMarketplace=true`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)

    return httpService.get(`/tables/bids/export?${query}`, { responseType: 'blob' })
  },
  getMarketBids(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Bid>>> {
    baseUrl()

    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    query += `&isMarketplace=true`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query += `&v2=true`

    query = encodeURI(query)

    return httpService.get(`/tables/bids?${query}`)
  },
  rejectBids(bidIds: number[]): Promise<AxiosResponse> {
    return httpService.post(
      `/bids/rejectBids`,
      {
        bidIds
      }
    )
  },
  restoreBids(bidIds: number[]): Promise<AxiosResponse> {
    return httpService.post(
      `/bids/restoreBids`,
      {
        bidIds
      }
    )
  },
  editBidNoReferral(payload: Bid): Promise<AxiosResponse> {
    return httpService.patch(`/bids/editBid`, payload)
  },
  acceptBid(bidId: number): Promise<AxiosResponse> {
    return httpService.get(`/bids/${bidId}/acceptBid`)
  },
}
